import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`3 Mile Run for time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Today is our True Grit 6 competition!  We will have no classes or
open gym on today but if you aren’t competing, judging or volunteering
come out and cheer on the competitors!  The first heat will start at
9:00am.  **`}</strong>{`For more info go to:
`}<a parentName="p" {...{
        "href": "http://truegritcomp.wordpress.com/"
      }}>{`truegritcomp.wordpress.com`}</a>{`***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Main Street will be closed until 10:30am due to the Humana 5K.  If
you are competing in True Grit today please give yourself time to get
here, you may have to park on Market Street and walk to The Ville.  Heat
1 starts promptly at 9:00am.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts on 9/23.  For more info email Eric
at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      